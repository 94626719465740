// Critical styles only for home
@import "../1-base/variables";
@import "../1-base/breakpoints";

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
:after,
:before {
    box-sizing: inherit
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

body {
    margin: 0;
    font-family: Lato, sans-serif
}

body[data-aos-easing] {
    overflow-x: hidden
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

a {
    background-color: rgba(0, 0, 0, 0)
}

small {
    font-size: 80%
}

img {
    border-style: none
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

textarea {
    overflow: auto
}

@media (min-width: 576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px
    }
        
    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}


.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

@media (min-width: 576px) {
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
}

@media (min-width: 768px) { 
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .offset-md-1 {
        margin-left: 8.333333%
    }
}

@media (min-width: 992px) { 
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .offset-lg-1 {
        margin-left: 8.333333%
    }
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important
}

.mt-5,
.my-5 {
    margin-top: 3rem !important
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

::selection {
    background: #f0eaf9;
    color: #fff
}

::-webkit-scrollbar {
    width: 8px
}

::-webkit-scrollbar-track {
    background: #eee
}

::-webkit-scrollbar-thumb {
    background: #d2c3ed;
    border-radius: 5px
}

::-webkit-scrollbar-thumb:hover {
    background: #b59be2
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Roboto Slab, serif;
    font-weight: 700
}

a {
    color: #191919;
    transition: color .4s ease-in-out
}

a,
a:hover {
    text-decoration: none
}

a:hover {
    color: #d61e00
}

a.lang {
    margin-left: 8px
}

a.lang img {
    vertical-align: middle
}

a:hover,
button:hover,
input[type=submit]:hover {
    cursor: pointer
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.black-block,
.blue-block,
.lightblue-block,
.time-line-home {
    padding: 50px 0
}

.black-block.not-found,
.blue-block.not-found,
.lightblue-block.not-found,
.time-line-home.not-found {
    padding-bottom: 50px
}

@media (min-width: 62em) {

    .black-block.not-found,
    .blue-block.not-found,
    .lightblue-block.not-found,
    .time-line-home.not-found {
        padding-bottom: 100px
    }
}

.black-block,
.blue-block {
    color: #fff
}

.black-block {
    background: #101010
}

.lightblue-block {
    background: #f0eaf9
}

.blue-block {
    background: #20113a
}

small {
    font-size: 70%;
    font-weight: 300;
    text-transform: uppercase
}

.btn {
    display: inline-block;
    padding: 7px 20px;
    transition: all .4s ease-in-out
}

.btn.btn-big {
    padding: 20px 40px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px
}

.btn:hover {
    cursor: pointer
}

.btn.disabled,
.btn.disabled:hover,
.btn:disabled,
.btn:disabled:hover {
    cursor: not-allowed;
    opacity: .5;
    background: #ccc;
    border-color: #ccc;
    color: #f7f7f7
}

.btn-blue,
.btn-red {
    color: #fff
}

.btn-blue:hover,
.btn-red:hover {
    background: rgba(0, 0, 0, 0)
}

.btn-red {
    background: #d61e00;
    border: 1px solid #d61e00
}

.btn-blue {
    background: #20113a;
    border: 1px solid #20113a
}

.btn-blue:hover {
    color: #20113a;
    border-color: #20113a
}

.btn-bluelight {
    background: #f0eaf9
}

.btn-bluelight:hover {
    background: #20113a;
    color: #fff
}

.milestone {
    text-align: center
}

.milestone svg {
    color: #ff2400;
    height: 72px;
    width: 72px;
}

.milestone h5 {
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 100%;
    position: relative;
    padding-top: 16px
}

.milestone h5:after {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 3px;
    top: 0;
    background: #fff;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%)
}

.milestone .milestone-number {
    font-size: 48px
}

.milestone .milestone-top {
    margin-top: 20px
}

nav ul {
    padding: 0;
    margin: 15px 0
}

nav ul li {
    display: inline-block;
    margin-right: 5px;

    @include breakpoint(laptop) {
        margin-right: 15px;
    }
}

// @media (min-width: 75em) {
//     nav ul li {
//         margin-right: 15px
//     }
// }

nav ul li:last-child {
    margin-right: 0
}

nav ul a {
    color: #000;
    transition: color .5s;
    display: inline-block;
    padding: 10px;
    position: relative;
    transition: color .5s ease-in-out;
    @include breakpoint(laptop) {
        font-size: 13px;
    }
}

nav ul a:focus,
nav ul a:hover {
    color: #20113a;
    opacity: .9
}

nav ul .current a {
    color: #20113a;
    opacity: 1
}

.mobile-menu {
    display: none;
    position: fixed;
    background: #fff;
    width: 100%;
    z-index: 999
}

.mobile-menu .header-nav,
.mobile-menu .header-nav .sub-menu,
.mobile-menu ul li {
    display: block
}

.mobile-menu .sub-menu {
    padding-left: 20px
}

@media (min-width: 62em) {
    .mobile-menu {
        display: none !important
    }
}

.header-nav .sub-menu {
    display: none
}

.header-nav .is-parent {
    position: relative
}

@media (min-width: 62em) {
    .header-nav .is-parent:hover>a:after {
        width: 100%
    }

    .header-nav .menu-item.is-parent:hover {
        overflow: visible
    }

    .header-nav .menu-item.is-parent:hover .sub-menu {
        display: block
    }

    .header-nav .sub-menu {
        position: absolute;
        background: #fff;
        z-index: 9;
        text-align: left;
        padding-bottom: 10px;
        margin-top: 0
    }

    .header-nav .sub-menu li {
        display: block;
        margin-right: 10px;
        margin-left: 10px
    }
}

.site-header {
    background: #fff;
    background-size: cover;
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px
}

.site-header .menu-item a:after {
    content: " ";
    height: 2px;
    width: 0;
    background: #20113a;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all .4s ease-in-out
}

.site-header .menu-item a:focus,
.site-header .menu-item a:hover {
    color: #20113a
}

.site-header .menu-item a:focus:after,
.site-header .menu-item a:hover:after {
    width: 100%;
    background: #20113a
}

.site-header .current a {
    color: #d61e00
}

.site-header .current a:after {
    width: 100%;
    background: #d61e00
}

.site-header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    box-shadow: 0 0 4px rgba(0, 0, 0, .46)
}

.site-logo {
    margin: 5px 0
}

.header-nav {
    display: none
}

@media (min-width: 62em) {
    .header-nav {
        display: block !important
    }
}

.nav-toggle {
    padding: 10px;
    background: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
    margin: 7px 0;
    top: 50%;
    left: 50%
}

.nav-toggle:focus {
    outline-width: 0
}

.nav-toggle [class*=bar-] {
    background: #20113a;
    display: block;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: all .2s ease;
    border-radius: 0;
    height: 2px;
    width: 30px;
    margin-bottom: 5px
}

.nav-toggle .bar-bot {
    margin-bottom: 0
}

.nav-toggle.opened .bar-top {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 15% 15%;
    transform-origin: 15% 15%
}

.nav-toggle.opened .bar-mid {
    opacity: 0
}

.nav-toggle.opened .bar-bot {
    -webkit-transform: rotate(45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 15% 95%;
    transform-origin: 15% 95%
}

@media (min-width: 62em) {
    .nav-toggle {
        display: none !important
    }
}

.top-bar {
    background: #20113a
}

.top-bar,
.top-bar a {
    color: #fff
}

.top-bar .col-12 {
    text-align: center
}

@media (min-width: 37.5em) {
    .top-bar .col-12:first-child {
        text-align: left
    }

    .top-bar .col-12:last-child {
        text-align: right
    }
}

.slick-arrow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    background: #fff 50% no-repeat;
    border: 0;
    opacity: .5;
    color: #000;
    padding: 10px;
    font-size: 20px
}

.slick-arrow:focus,
.slick-arrow:hover {
    outline: none;
    opacity: 1;
    color: #000
}

.slick-prev {
    left: 0
}

.slick-next {
    right: 0
}

.home-slider-container .item-container {
    position: relative
}

.home-slider-container .main-text {
    position: absolute;
    left: 0;
    top: 20%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 99;
    color: #fff;
    text-align: center;
    width: 100%
}

.home-slider-container .main-text h1 {
    font-size: 15px
}

.home-slider-container .overlay {
    background-color: #000;
    opacity: .4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

@media (min-width: 37.5em) {
    .home-slider-container .main-text h1 {
        font-size: 30px
    }
}

@media (min-width: 62em) {
    .home-slider-container .main-text {
        top: 50%
    }

    .home-slider-container .main-text h1 {
        font-size: 100px
    }
}

.home-slider img {
    width: 100%
}

.site-footer {
    background: #20113a;
    color: #fff
}

.site-footer a {
    color: #fff
}

.site-footer a:hover {
    color: #d61e00
}

.site-footer .pre-footer {
    padding: 20px 0
}

.site-footer .copy {
    background: #170c2a
}

.site-footer .copy .col-12 {
    text-align: center
}

@media (min-width: 62em) {
    .site-footer .copy .col-12:first-child {
        text-align: left
    }

    .site-footer .copy .col-12:last-child {
        text-align: right
    }
}

#up {
    position: fixed;
    right: 15px;
    bottom: 95px;
    background: #101010;
    opacity: .7;
    transition: opacity .4s ease-in-out;
    display: none
}

#up:hover {
    opacity: 1
}

#up:hover a,
#up a {
    color: #f0eaf9
}

#up a {
    display: block;
    padding: 15px;
    line-height: 1em
}

#up img {
    max-width: 100%
}

.privacidad-bar {
    background: #101010;
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%
}

.privacidad-bar a:not(.btn) {
    color: #fff;
    text-decoration: underline
}

.social-network a {
    display: inline-block;
    margin-right: 20px;
    font-size: 1.3em
}

.social-network a:last-child {
    margin-right: 0
}

.time-line-home h2,
.time-line-home h3 {
    margin: 0
}

.time-line-home h2 {
    font-size: 70px
}

.time-line-home h3 {
    font-size: 40px
}

.time-line-home .timeline-group {
    margin-top: 40px;
    margin-bottom: 40px
}

.timeline-group {
    position: relative
}

.timeline-group .timeline-line {
    background: #170c2a;
    height: 4px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    display: none
}

@media (min-width: 62em) {
    .timeline-group .timeline-line {
        display: block
    }
}

.timeline-block-item:before,
.timeline-item .point {
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 100%;
    border: 4px solid #170c2a
}

.timeline-item .point-holder {
    position: absolute;
    bottom: -6px;
    z-index: 9;
    width: 100%;
    display: none
}

@media (min-width: 62em) {
    .timeline-item .point-holder {
        display: block
    }
}

.timeline-item .point {
    margin: 0 auto
}

.timeline-block-item {
    width: 100%;
    overflow: auto
}

.timeline-block-item:after {
    content: "";
    clear: both;
    display: table
}

.timeline-block-item .item>p {
    margin-top: 0
}

@media (min-width: 62em) {
    .timeline-block-item:nth-child(2n):before {
        left: auto;
        right: 49.2%
    }

    .timeline-block-item:before {
        content: "";
        position: absolute;
        left: 49.5%;
        z-index: 2
    }

    .timeline-block-item .item {
        width: 48%;
        float: left;
        margin-right: 2%
    }

    .timeline-block-item:nth-child(2n) .item {
        float: right;
        margin-right: 0;
        margin-left: 2%
    }
}

.timeline-main {
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden
}

.timeline-main .timeline-line {
    position: absolute;
    z-index: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    width: 4px;
    height: 100%;
    background: #20113a;
    left: 50%
}

.file-input,
input,
textarea {
    width: 100%;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid #f0eaf9;
    margin-bottom: 15px
}

.suscripcion {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .34);
    display: none;
    z-index: 99999
}

.suscripcion .content-form {
    background: #f0eaf9;
    padding: 10px 30px
}

.suscripcion .content {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

#bio_ep_bg,
#bio_ep_close {
    display: none !important
}

.subscription-form {
    display: none
}

.form-inline {

    @media (min-width: 62em) {
        display: flex;

        input {
            margin-bottom: 0px;
            border-radius: 0px;
        }
    }
}

input {
    width: 100%;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid $bluelight;
    margin-bottom: 15px;

    &:focus {
        border-color: $bluedark;
        outline: none;
    }
}
// slick slider

// data aos